import { ISessionApi } from '@shapediver/viewer';
import { isGuid } from '../../helpers/isGuid';
import { logProblem } from '../../helpers/logProblem';

export function updateSessionParameter(session: ISessionApi, key: string, value: string) {
  if (!isGuid(key)) {
    const prm = session.getParameterByName(key);
    // console.log('Parameter name', key, prm);
    if (!prm || !prm.length) {
      logProblem('Parameter not found by name (update)', { key, value });
    } else {
      const prmId = prm[0].id;
      session.parameters[prmId].value = value;
    }
  } else {
    const prm = session.getParameterById(key);
    if (!prm) {
      logProblem('Parameter not found by ID (update)', { key, value });
    } else {
      session.parameters[prm.id].value = value;
    }
  }
}

export function resetSessionParameter(session: ISessionApi, key: string) {
  if (!isGuid(key)) {
    const prm = session.getParameterByName(key);
    // console.log('Parameter name', key, prm);
    if (!prm || !prm.length) {
      logProblem('Parameter not found by name (reset)', { key });
    } else {
      const prmId = prm[0].id;
      session.parameters[prmId].resetToDefaultValue();
    }
  } else {
    const prm = session.getParameterById(key);
    if (!prm) {
      logProblem('Parameter not found by ID (reset)', { key });
    } else {
      session.parameters[prm.id].resetToDefaultValue();
    }
  }
}

export function applySessionParameters(session?: ISessionApi | null) {
  session?.customize();
}
